import { Button } from "../../generic/Button/Button";
import { Modal } from "../../generic/Modal/Modal";
import { switchLanguage } from "../../state/languageSlice";
import { languageActions } from "../../state/machineSlices";
import { closeModal } from "../../state/modalSlice";
import { useDispatch, useSelector } from "../../state/store";

import "./CalculusSelectionModal.css";

export const CalculusSelectModal = () => {
  const modal = useSelector((state) => state.ui.modal);
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={modal === "Langauge Select"}
      onRequestClose={() => dispatch(closeModal())}
      style={{ content: { width: "50%" } }}
    >
      <h2 className="modal-title">Calculus Selection</h2>
      <div className="legend">
        <div>&#x2705; &ndash; Language is Available</div>
        <div>&#x274C; &ndash; Language is Unavailable</div>
      </div>
      <h3 className="toc">Untyped Languages</h3>
      <ul className="toc">
        <li
          className="toc-link"
          onClick={() => {
            if (language !== "SK") {
              dispatch(languageActions[language].reset());
              dispatch(switchLanguage("SK"));
            }
            dispatch(closeModal());
          }}
        >
          <span className="title">SK</span>
          <span className="chapter">&#x2705;</span>
          {language == "SK" && <span className="selected">Selected</span>}
        </li>
        <li
          className="toc-link"
          onClick={() => {
            if (language !== "Lambda") {
              dispatch(languageActions[language].reset());
              dispatch(switchLanguage("Lambda"));
            }
            dispatch(closeModal());
          }}
        >
          <span className="title">&lambda;</span>
          <span className="chapter">&#x2705;</span>
          {language == "Lambda" && <span className="selected">Selected</span>}
        </li>
      </ul>
      <h3 className="toc">Typed Languages</h3>
      <ul className="toc">
        <li>
          <span className="title">
            &lambda;<sub>&#x2794;</sub>
          </span>
          <span className="chapter">&#x274C;</span>
        </li>
        <li>
          <span className="title">
            &lambda;<span style={{ textDecoration: "underline" }}>&omega;</span>
          </span>
          <span className="chapter">&#x274C;</span>
        </li>
        <li>
          <span className="title">&lambda;2</span>
          <span className="chapter">&#x274C;</span>
        </li>
        <li>
          <span className="title">&lambda;P</span>
          <span className="chapter">&#x274C;</span>
        </li>
        <li>
          <span className="title">
            &lambda;P
            <span style={{ textDecoration: "underline" }}>&omega;</span>
          </span>
          <span className="chapter">&#x274C;</span>
        </li>
        <li>
          <span className="title">&lambda;P2</span>
          <span className="chapter">&#x274C;</span>
        </li>
        <li>
          <span className="title">&lambda;&omega;</span>
          <span className="chapter">&#x274C;</span>
        </li>
        <li>
          <span className="title">&lambda;C</span>
          <span className="chapter">&#x274C;</span>
        </li>
      </ul>
      <Button onClick={() => dispatch(closeModal())} type="Subdued">
        Close Modal
      </Button>
    </Modal>
  );
};
