import { Modal } from "../../generic/Modal/Modal";
import { Button } from "../../generic/Button/Button";
import { closeModal } from "../../state/modalSlice";
import { useDispatch, useSelector } from "../../state/store";

import "./TermModal.css";
import * as languages from "../../_languages/languages";

export const TermModal = () => {
  const modal = useSelector((state) => state.ui.modal);
  const language = useSelector((state) => state.language);
  const term = useSelector((state) => state.machine[language].term);
  const dispatch = useDispatch();

  const termStr =
    term !== null && term !== undefined
      ? languages[language].termPrinter(term as never).trim()
      : "";

  const display = termStr.length === 1 ? termStr : `( ${termStr} )`;

  return (
    <Modal
      isOpen={modal === "Term Display"}
      onRequestClose={() => dispatch(closeModal())}
    >
      <div className="code-display">
        <h2>Term Viewer</h2>
        <code className="code-text">{display}</code>
        <Button
          type="Subdued"
          style={{ width: "100%" }}
          onClick={() => dispatch(closeModal())}
        >
          Close Modal
        </Button>
      </div>
    </Modal>
  );
};
