import ReactModal from "react-modal";
import "./Modal.css";

type ModalProps = JSX.IntrinsicAttributes &
  JSX.IntrinsicClassAttributes<ReactModal> &
  Readonly<ReactModal.Props>;

export const Modal = (props: ModalProps) => (
  <ReactModal
    {...props}
    className="modal-content"
    overlayClassName="modal-overlay"
  />
);
