import React from "react";

import code_box from "./code-box.png";
import func_box from "./function-box.png";
import rep_box from "./representation-box.png";

import "../InstructionsModal.css";

export const OverviewContent = () => (
  <React.Fragment>
    <section>
      <h3>Introduction</h3>
      <p>
        The functional programming paradigm has long been an academic
        discipline, but in recent years many practical languages have been
        adding functional concepts. Because the paradigm is gaining ground, it
        may behoove programmers to learn how such languages might be
        interpreted. This website intends to serve as a resource in that
        pursuit. It provides one visual interpreter for various functional
        languages, all closely related to one another.
      </p>
    </section>
    <section>
      <h3>Interface</h3>
      <p>
        The close relationships shared among the languages available on this
        website mean that only one interface is needed for visualizing
        interpreter behavior. As such, The following section gives a general
        overview. Anything specific to a language, such as syntax, can be viewed
        in the panel for that language by selecting the appropriate tab above.
      </p>

      <section>
        <h4>Code Box</h4>
        <img src={code_box} className="instr-img left" />
        <p>
          The Code Box, circled in the picture to the left, acts as the code
          editor. All declarations and definitions for a program are written
          here. Clicking “Validate” starts the validator for the currently
          selected language. The validator verifies that the code written in the
          Code Box conforms to the selected language, both syntactically and
          semantically. If everything passes, the code is loaded into the
          interpreter, and the definitions can be used in the Function Box.
        </p>
        <br />
      </section>

      <section>
        <h4>Function Box</h4>
        <img src={func_box} className="instr-img right" />
        <p>
          The Function Box, circled in the picture on the right, acts as the
          location to input an actual function call. If the code in the Code Box
          has been validated, then definitions there are available for use in
          the Function Box. However, the Function Box can only contain one
          top-level function call. Arguments to said function call may be
          function calls themselves, though. Once there is a function call
          available for processing, clicking on “Load” will attempt to parse the
          call and prep it for evaluation. If everything works, the initial
          state is shown in the Representation Area.
        </p>
        <br />
      </section>

      <section>
        <h4>Representation Area</h4>
        <img src={rep_box} className="instr-img left" />
        <p>
          The Representation Area, circled in the picture on the left, is the
          main reason for using this tool. This portion of the interface
          provides navigation, step by step, through the processing of the
          function call loaded from the Function Box. However, the processing
          does not happen in an automatic fashion. The buttons below the area
          allow for navigation through the call, either step-by-step, or
          straight through to termination.
        </p>
      </section>
    </section>
  </React.Fragment>
);
