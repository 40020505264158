import { ParseNode } from "./parser";

export const print = (term: ParseNode): string => {
  switch (term.nature) {
    case "Ident":
    case "S":
    case "K":
      return term.name + " ";

    case "App": {
      const ret_val = print(term.children[0]);

      return term.children[1].nature === "App"
        ? ret_val + `( ${print(term.children[1])}) `
        : ret_val + print(term.children[1]);
    }
  }
};

export const sampleCode = `
I $= ( S K K )
B $= ( S ( K S ) K )
T $= ( B ( S I ) K )
V $= ( S ( B B B B B B B B B B S T ) ( K K ) )
C $= ( S ( B B S ) ( K K ) )

true $= K
false $= ( K I )

not $= ( V ( K I ) K )
imp $= ( C C true )
and $= ( C C false )
iff $= ( C S not )
or $= ( T true )
`
  .trim()
  .concat("\n");

export const sampleFunc = "( iff false true )";
