import { DefinitionList, LcAbs, ParseNode } from "./parser";

const openTermHelper = (
  term: ParseNode,
  arg: ParseNode,
  index: number
): ParseNode => {
  switch (term.nature) {
    case "Ident":
      return term;
    case "Bvar":
      return term.index === index ? arg : term;
    case "App":
      return {
        nature: "App",
        name: "",
        children: [
          openTermHelper(term.children[0], arg, index),
          openTermHelper(term.children[1], arg, index),
        ],
      };
    case "LcAbs":
      return {
        nature: "LcAbs",
        name: "λ",
        children: [openTermHelper(term.children[0], arg, index + 1)],
      };
  }
};

const openTerm = (term: ParseNode, arg: ParseNode) =>
  openTermHelper(term, arg, -1);

export const reduce = (
  defs: DefinitionList,
  term: ParseNode
): ParseNode | "Irreducible" => {
  switch (term.nature) {
    case "Bvar":
      return "Irreducible";

    case "Ident":
      return defs[term.name] ?? "Irreducible";

    case "LcAbs": {
      const reducedBody = reduce(defs, term.children[0]);

      if (reducedBody !== "Irreducible") {
        return {
          nature: "LcAbs",
          name: "λ",
          children: [reducedBody],
        };
      }

      return "Irreducible";
    }

    case "App": {
      if (term.children[0].nature === "LcAbs") {
        const openedAbs = openTerm(term.children[0], term.children[1]);

        return (openedAbs as LcAbs).children[0];
      } else {
        const reducedLeft = reduce(defs, term.children[0]);

        if (reducedLeft !== "Irreducible") {
          return {
            nature: "App",
            name: "",
            children: [reducedLeft, term.children[1]],
          };
        }

        const reducedRight = reduce(defs, term.children[1]);

        if (reducedRight !== "Irreducible") {
          return {
            nature: "App",
            name: "",
            children: [term.children[0], reducedRight],
          };
        }

        return "Irreducible";
      }
    }
  }
};

export const normalize = (
  defs: DefinitionList,
  term: ParseNode
): ParseNode | "Irreducible" => {
  let previousTerm = term;
  let currentTerm = reduce(defs, term);

  if (currentTerm === "Irreducible") {
    return "Irreducible";
  }

  while (currentTerm !== "Irreducible") {
    previousTerm = currentTerm;
    currentTerm = reduce(defs, previousTerm);
  }

  return previousTerm;
};
