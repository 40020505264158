import React from "react";

import program from "./program.png";
import definition from "./definition.png";
import term from "./term.png";

export const SKContent = () => (
  <React.Fragment>
    <section>
      <h3>Overview</h3>
      <p>
        The SK Calculus is <em>the</em> minimal functional language. There are
        only two built-in primitive functions, with everything else being built
        from these alone. Nevertheless, it is a Turing&#8209;complete language,
        so many things are possible. It could be considered an assembly language
        of functional programming.
      </p>
    </section>
    <section>
      <h3>Syntax</h3>
      <p>
        Because the SK Calculus language is minimal, the syntax is also minimal.
        The following sections present the syntax used for this implementation.
        In addition to the textual explanation given here, there is also a
        visual syntax diagram given at the end of the instructions.
      </p>
      <section>
        <h4>Definitions</h4>
        <p>
          In this implementation, function definitions have the form{" "}
          <code>id $= &lt;term&gt;</code> where <code>id</code> is any sequence
          of non-whitespace characters or symbols that itself is not a reserved
          string. (The reserved strings are shown below.) This very relaxed
          definition means that a character sequence like{" "}
          <code>&lt;&gt;&lt;</code> is allowed as an identifier.
        </p>
        <div className="reserved-strings">
          <div className="display">
            <code>S</code>
            <code>K</code>
            <code>(</code>
            <code>)</code>
            <code>$=</code>
          </div>
          <div className="caption">Reserved Strings</div>
        </div>
        <p></p>
      </section>
      <section>
        <h4>Terms</h4>
        <p>
          The form of a <code>&lt;term&gt;</code> is also relatively simple.
          There are only two possibilities:
        </p>
        <div className="display">
          <ol>
            <li>
              An <code>id</code> or a primitive function <code>S</code> or{" "}
              <code>K</code> written entirely by itself
            </li>
            <li>
              A parenthesized list of terms separated by whitespace, i.e,{" "}
              <code>
                ( &lt;term<sub>1</sub>&gt; ... &lt;term<sub>n</sub>&gt; )
              </code>
            </li>
          </ol>
        </div>
        <p></p>
      </section>
    </section>
    <section>
      <h3>Semantics</h3>
      <p>
        There is effectively only one operation in the SK Calculus: the
        evaluation of a function call. Evaluating a function effectively acts as
        a re-write operation, and there are three possible rewrites that can
        occur in the language. One for either of the primitives, and one for any
        id that has an associated definition. Here are shown the specific
        operations for each of these rewrites:
      </p>
      <div className="display">
        <ol>
          <li>
            Rewrite terms of the form <code>( S x y z )</code> to{" "}
            <code>( ( x z ) ( y z ) )</code>
          </li>
          <li>
            Rewrite terms of the form <code>( K x y )</code> to <code>x</code>
          </li>
          <li>
            Rewrite terms of the form <code>id</code> where{" "}
            <code>id $= &lt;term&gt;</code> to <code>&lt;term&gt;</code>
          </li>
          <li>
            Leave terms of the form <code>id</code> that have no definition
            exactly as they are
          </li>
        </ol>
      </div>
      <p>
        At each step of operation, the outer-most, left-most term that can be
        re-written is replaced, and the steps continue from there. Once no
        re-writes are possible, the evaluation of the function is finished.
        Correctly interpreting the resulting expression will provide the value
        of the calculation.
      </p>
      <p>
        Beyond that, nothing more is needed to use the SK Calculus as a
        programming language. Enjoy!
      </p>
    </section>
    <section>
      <h3>Diagram</h3>
      <div className="railroad-diagram">
        <div>
          <div className="caption">Program</div>
          <img src={program} className="railorad" />
        </div>
        <div>
          <div className="caption">Definition</div>
          <img src={definition} />
        </div>
        <div>
          <div className="caption">Term</div>
          <img src={term} />
        </div>
      </div>
    </section>
  </React.Fragment>
);
