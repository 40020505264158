import { Token, TokenName } from "./scanner";

export type DefinitionList = Record<string, ParseNode>;

type IdentNode = {
  nature: "Ident";
  name: string;
};

type BoundVar = {
  nature: "Bvar";
  name: `⌈${number}⌉`;
  index: number;
};

type AppNode = {
  nature: "App";
  name: "";
  children: [ParseNode, ParseNode];
};

type AbsNode = {
  nature: "Abs";
  args: IdentNode[];
  body: ParseNode;
};

export type ParseNode = LcAbs | AppNode | BoundVar | IdentNode;

export type LcAbs = { nature: "LcAbs"; name: "λ"; children: [ParseNode] };

type ParseError =
  | ["Unexpected", TokenName | "EOF"]
  | ["Redeclaration", string]
  | ["Undefined", string]
  | ["Unknown", string];

export const isParseError = <T>(type: T | ParseError): type is ParseError => {
  const parseStrings: Array<ParseError[0]> = [
    "Redeclaration",
    "Undefined",
    "Unexpected",
    "Unknown",
  ];

  return (
    Array.isArray(type) && type.length == 2 && parseStrings.includes(type[0])
  );
};

/**
 * term = identifier
 *      | ( term+ )
 *      | ( .\ identifier+ => term )
 */

const parseDefinition = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { name: string; definition: ParseNode; index: number } | ParseError => {
  // Verify that we haven't reached the end of the input
  if (typeof tokens[index] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (typeof tokens[index + 1] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (typeof tokens[index + 2] === "undefined") {
    return ["Unexpected", "EOF"];
  }

  // Check that the next two tokens constitute a definition
  if (tokens[index].type !== "Ident") {
    return ["Unexpected", tokens[index].type];
  }
  if (tokens[index + 1].type !== "Defsym") {
    return ["Unexpected", tokens[index + 1].type];
  }

  // Verify that the definition name does not already exist
  if (typeof definitions[tokens[index].raw] !== "undefined") {
    return ["Redeclaration", tokens[index].raw];
  }

  const name = tokens[index].raw;
  const result = parseTerm(definitions, tokens, index + 2);

  return isParseError(result)
    ? result
    : { name, definition: result.node, index: result.index };
};

export const parseTerm = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { node: ParseNode; index: number } | ParseError => {
  if (typeof tokens[index] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (tokens[index].type === "Defsym") {
    return ["Unexpected", "Defsym"];
  }
  if (tokens[index].type === "Arrow") {
    return ["Unexpected", "Arrow"];
  }
  if (tokens[index].type === "Lambda") {
    return ["Unexpected", "Lambda"];
  }
  if (tokens[index].type === "RParen") {
    return ["Unexpected", "RParen"];
  }

  switch (tokens[index].type) {
    case "Lparen":
      if (tokens[index + 1].type === "Lambda") {
        return parseAbstraction(definitions, tokens, index + 1);
      } else {
        return parseTermList(definitions, tokens, index);
      }
    case "Ident":
      return {
        node: { nature: "Ident", name: tokens[index].raw },
        index: index + 1,
      };
  }

  return ["Unknown", tokens[index].type];
};

const parseAbstraction = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { node: ParseNode; index: number } | ParseError => {
  // Verify that we haven't reached the end of the input
  if (typeof tokens[index] === "undefined") {
    return ["Unexpected", "EOF"];
  }

  // Check that we are actually working in an abstraction
  if (tokens[index].type !== "Lambda") {
    return ["Unexpected", tokens[index].type];
  }

  // Get the argument list
  const argListResult = parseIdentList(definitions, tokens, index + 1);

  // Verify the argument list is followed by an arrow
  if (typeof tokens[argListResult.index] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (tokens[argListResult.index].type !== "Arrow") {
    return ["Unexpected", tokens[argListResult.index].type];
  }

  // Get the body
  const bodyResult = parseTerm(definitions, tokens, argListResult.index + 1);

  if (isParseError(bodyResult)) {
    return bodyResult;
  }

  // Ensure the function was closed off appropriately
  if (typeof tokens[bodyResult.index] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (tokens[bodyResult.index].type !== "RParen") {
    return ["Unexpected", tokens[argListResult.index].type];
  }

  return {
    node: curry({
      nature: "Abs",
      args: argListResult.list,
      body: bodyResult.node,
    }),
    index: bodyResult.index + 1,
  };
};

const parseIdentList = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { list: IdentNode[]; index: number } => {
  const identNodes: IdentNode[] = [];

  let currentIndex = index;

  while (
    typeof tokens[currentIndex] !== "undefined" &&
    tokens[currentIndex].type === "Ident"
  ) {
    identNodes.push({
      nature: "Ident",
      name: tokens[currentIndex].raw,
    });
    currentIndex = currentIndex + 1;
  }

  return { list: identNodes, index: currentIndex };
};

const closeTermHelper = (
  term: ParseNode,
  arg: IdentNode,
  index: number
): ParseNode => {
  switch (term.nature) {
    case "Ident":
      return term.name === arg.name
        ? { nature: "Bvar", name: `⌈${index}⌉`, index }
        : term;
    case "Bvar":
      return term;
    case "App":
      return {
        nature: "App",
        name: "",
        children: [
          closeTermHelper(term.children[0], arg, index),
          closeTermHelper(term.children[1], arg, index),
        ],
      };
    case "LcAbs":
      return {
        nature: "LcAbs",
        name: "λ",
        children: [closeTermHelper(term.children[0], arg, index + 1)],
      };
  }
};

const closeTerm = (term: ParseNode, arg: IdentNode): ParseNode =>
  closeTermHelper(term, arg, 0);

const curry = (node: AbsNode): ParseNode => {
  const argList = node.args;

  let currentIndex = argList.length - 1;
  let currentBody: ParseNode = node.body;

  while (currentIndex >= 0) {
    const newBody: ParseNode = {
      nature: "LcAbs",
      name: "λ",
      children: [closeTerm(currentBody, argList[currentIndex])],
    };
    currentBody = newBody;
    currentIndex = currentIndex - 1;
  }

  return currentBody as ParseNode;
};

const parseTermList = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { node: ParseNode; index: number } | ParseError => {
  const parseResult = parseTerm(definitions, tokens, index + 1);

  if (isParseError(parseResult)) {
    return parseResult;
  }

  let currentNode = parseResult.node;
  let currentIndex = parseResult.index;

  while (
    currentIndex < tokens.length &&
    tokens[currentIndex].type !== "RParen"
  ) {
    const parseResult = parseTerm(definitions, tokens, currentIndex);

    if (isParseError(parseResult)) {
      return parseResult;
    }

    currentNode = {
      nature: "App",
      name: "",
      children: [currentNode, parseResult.node],
    };

    currentIndex = parseResult.index;
  }

  if (currentIndex > tokens.length) {
    if (tokens[tokens.length - 1].type !== "RParen") {
      return ["Unexpected", "EOF"];
    }
  }

  return { node: currentNode, index: currentIndex + 1 };
};

export const parseDefinitionList = (
  tokens: Token[]
): DefinitionList | ParseError => {
  const definitions: DefinitionList = {};

  let currentIndex = 0;

  while (currentIndex < tokens.length) {
    const result = parseDefinition(definitions, tokens, currentIndex);

    if (isParseError(result)) {
      return result;
    }

    definitions[result.name] = result.definition;
    currentIndex = result.index;
  }

  return definitions;
};
