import { createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  DefinitionList,
  Lambda,
  Language,
  LanguageName,
  SK,
  isParseError,
} from "../_languages/languages";
import { castDraft } from "immer";

type LanguageState<NTypes> = {
  definitions: DefinitionList<NTypes>;
  term: NTypes | null;
};

export const languageSliceGenerator = <TNames, NTypes>(
  language: Language<TNames, NTypes>
) =>
  createSlice({
    name: language.name,
    initialState: {} as LanguageState<NTypes>,
    reducers: {
      setDefinitions: (state, action: { payload: string }) => {
        const scanResult = language.scanner(action.payload);
        const parseResult = language.defParser(scanResult);

        if (isParseError<TNames, unknown>(parseResult)) {
          toast.error("There was an error parsing the Code");
        } else {
          toast.success("The Code has been Validated");
          state.definitions = castDraft(parseResult);
        }
      },
      setTerm: (state, action: { payload: string }) => {
        const scanResult = language.scanner(action.payload);
        const parseResult = language.termParser(
          state.definitions as DefinitionList<NTypes>,
          scanResult,
          0
        );

        if (isParseError(parseResult)) {
          toast.error("There was an error parsing the Function");
        } else {
          toast.success("The Function has been Loaded");
          state.term = castDraft(parseResult.node);
        }
      },
      reset: (state) => {
        state.definitions = {};
        state.term = null;
      },
      reduceTerm: (state) => {
        if (state.term === null) return state;

        const result = language.termReducer(
          state.definitions as DefinitionList<NTypes>,
          state.term as NTypes
        );

        if (result === "Irreducible") {
          toast.info("No more reductions are possible");
        } else {
          state.term = castDraft(result);
        }
      },
      normalizeTerm: (state) => {
        if (state.term === null) return state;

        const result = language.termNormalizer(
          state.definitions as DefinitionList<NTypes>,
          state.term as NTypes
        );

        if (result === "Irreducible") {
          toast.info("No more reductions are possible");
        } else {
          state.term = castDraft(result);
        }
      },
    },
  });

const skSlice = languageSliceGenerator(SK);
const lambdaSlice = languageSliceGenerator(Lambda);

type SKActions = typeof skSlice.actions;
type LambdaActions = typeof lambdaSlice.actions;

export const languageActions: Record<LanguageName, SKActions | LambdaActions> =
  {
    SK: skSlice.actions,
    Lambda: lambdaSlice.actions,
  };

export const skReducer = skSlice.reducer;
export const lambdaReducer = lambdaSlice.reducer;
