import { createSlice } from "@reduxjs/toolkit";

export type ModalName =
  | "No Modal"
  | "Instructions"
  | "Langauge Select"
  | "Term Display";

export const modalSlice = createSlice({
  name: "modals",
  initialState: "No Modal" as ModalName,
  reducers: {
    openModal: (_state, action: { payload: ModalName }) => {
      return action.payload;
    },
    closeModal: (_state) => {
      return "No Modal";
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;
export const modalReducer = modalSlice.reducer;
