import { createSlice } from "@reduxjs/toolkit";
import { LanguageName } from "../_languages/languages";

export const languageSlice = createSlice({
  name: "modals",
  initialState: "SK" as LanguageName,
  reducers: {
    switchLanguage: (_state, action: { payload: LanguageName }) => {
      return action.payload;
    },
  },
});

export const { switchLanguage } = languageSlice.actions;
export const languageReducer = languageSlice.reducer;
