import { Link } from "../../generic/Link/Link";
import { openModal } from "../../state/modalSlice";
import { useDispatch, useSelector } from "../../state/store";
import "./Header.css";

export const Header = () => {
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  return (
    <header>
      <h1>{language} Calculus Viewer</h1>
      <div>
        <Link onClick={() => dispatch(openModal("Instructions"))}>
          View Instructions
        </Link>
        <Link onClick={() => dispatch(openModal("Langauge Select"))}>
          Select Language
        </Link>
      </div>
    </header>
  );
};
