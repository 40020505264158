import React from "react";
import Tree from "react-d3-tree";
import { Button } from "../../generic/Button/Button";
import { languageActions } from "../../state/machineSlices";
import { openModal } from "../../state/modalSlice";
import { useDispatch, useSelector } from "../../state/store";

import "./InterpreterArea.css";

export const InterpreterArea = () => {
  const language = useSelector((state) => state.language);
  const node = useSelector((state) => state.machine[language].term);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <h2>Representation Area</h2>
      <div className="tree-box">
        {node && (
          <Tree
            data={node}
            orientation={"vertical"}
            pathFunc={"straight"}
            collapsible={false}
            rootNodeClassName="node_root"
            branchNodeClassName="node_branch"
            leafNodeClassName="node_leaf"
          />
        )}
      </div>
      <div className="manipulations">
        <Button
          onClick={() => {
            dispatch(openModal("Term Display"));
          }}
          style={{ marginRight: "auto" }}
          disabled={node === null || node === undefined}
        >
          Written Form
        </Button>
        {/* <Button>Reset</Button> */}
        {/* <Button>Previous</Button> */}
        <Button
          disabled={node === null || node === undefined}
          onClick={() => {
            dispatch(languageActions[language].reduceTerm());
          }}
        >
          Step
        </Button>
        <Button
          disabled={node === null || node === undefined}
          onClick={() => {
            dispatch(languageActions[language].normalizeTerm());
          }}
        >
          Run
        </Button>
      </div>
    </React.Fragment>
  );
};
