import { ReactNode } from "react";
import "./link.css";

type LinkProps = {
  children: ReactNode;
  onClick: () => void;
};

export const Link = ({ children, onClick }: LinkProps) => (
  <p className="link" onClick={onClick}>
    {children}
  </p>
);
