export type TokenName =
  | "Lparen"
  | "RParen"
  | "Lambda"
  | "Arrow"
  | "Defsym"
  | "Ident";

export type Token = {
  type: TokenName;
  raw: string;
};

const selectToken = (tok: string): Token => {
  switch (tok) {
    case "(":
      return { type: "Lparen", raw: tok };
    case ")":
      return { type: "RParen", raw: tok };
    case ".\\":
      return { type: "Lambda", raw: tok };
    case "=>":
      return { type: "Arrow", raw: tok };
    case "$=":
      return { type: "Defsym", raw: tok };
    default:
      return { type: "Ident", raw: tok };
  }
};

export const scan = (input: string): Token[] =>
  input
    .split(/\s+/)
    .filter((str) => str.trim().length !== 0)
    .map(selectToken);
