///////////////////////////////// SK Language /////////////////////////////////
import {
  ParseNode as SKParseNode,
  parseTerm as skParseTerm,
  parseDefinitionList as skParseDefs,
} from "./sk_calculus/parser";

import {
  sampleCode as skSampleCode,
  sampleFunc as skSampleFunc,
  print as skPrintTerm,
} from "./sk_calculus/printer";

import {
  normalize as skNormalize,
  reduce as skReduce,
} from "./sk_calculus/reduction";

import {
  TokenName as SKTokenNames,
  scan as skScan,
} from "./sk_calculus/scanner";

/////////////////////////////// Lambda Language ///////////////////////////////
import {
  ParseNode as LambdaParseNode,
  parseDefinitionList as lambdaParseDefs,
  parseTerm as lambdaParseTerm,
} from "./lambda_calculus/parser";

import {
  TokenName as LambdaTokenName,
  scan as lambdaScan,
} from "./lambda_calculus/scanner";

import {
  print as lambdaPrint,
  sampleCode as lambdaSampleCode,
  sampleFunc as lambdaSampleFunc,
} from "./lambda_calculus/printer";

import {
  normalize as lambdaNormalize,
  reduce as lambdaReduce,
} from "./lambda_calculus/reduction";

//////////////////////////////// Generic Types ////////////////////////////////
export type Token<TNames> = {
  type: TNames;
  raw: string;
};
export type SelectTokenFunc<TNames> = (tok: string) => Token<TNames>;
export type Scanner<TNames> = (input: string) => Token<TNames>[];

type ParseError<TNames> =
  | ["Unexpected", TNames | "EOF"]
  | ["Redeclaration", string]
  | ["Undefined", string]
  | ["Unknown", string];

export const isParseError = <TNames, TAny>(
  type: TAny | ParseError<TNames>
): type is ParseError<TNames> => {
  const parseStrings: Array<ParseError<TNames>[0]> = [
    "Redeclaration",
    "Undefined",
    "Unexpected",
    "Unknown",
  ];

  return (
    Array.isArray(type) && type.length == 2 && parseStrings.includes(type[0])
  );
};

export type DefinitionParser<TNames, NTypes> = (
  toks: Token<TNames>[]
) => DefinitionList<NTypes> | ParseError<TNames>;

export type TermParser<TNames, NTypes> = (
  defs: DefinitionList<NTypes>,
  toks: Token<TNames>[],
  idx: number
) =>
  | {
      node: NTypes;
      index: number;
    }
  | ParseError<TNames>;

export type DefinitionList<NTypes> = Record<string, NTypes>;

export type TermPrinter<NTypes> = (term: NTypes) => string;

export type TermReducer<NTypes> = (
  defs: DefinitionList<NTypes>,
  term: NTypes
) => NTypes | "Irreducible";

export type TermNoramlizer<NTypes> = (
  defs: DefinitionList<NTypes>,
  term: NTypes
) => NTypes | "Irreducible";

//////////////////////////////// Language Defs ////////////////////////////////
export type LanguageName = "SK" | "Lambda";

export type Language<TNames, NTypes> = {
  name: LanguageName;
  scanner: Scanner<TNames>;
  defParser: DefinitionParser<TNames, NTypes>;
  termParser: TermParser<TNames, NTypes>;
  termPrinter: TermPrinter<NTypes>;
  termReducer: TermReducer<NTypes>;
  termNormalizer: TermNoramlizer<NTypes>;
  samples: {
    code: string;
    func: string;
  };
};

export const SK: Language<SKTokenNames, SKParseNode> = {
  name: "SK",
  scanner: skScan,
  defParser: skParseDefs,
  termParser: skParseTerm,
  termPrinter: skPrintTerm,
  termReducer: skReduce,
  termNormalizer: skNormalize,
  samples: {
    code: skSampleCode,
    func: skSampleFunc,
  },
};

export const Lambda: Language<LambdaTokenName, LambdaParseNode> = {
  name: "Lambda",
  scanner: lambdaScan,
  defParser: lambdaParseDefs,
  termParser: lambdaParseTerm,
  termPrinter: lambdaPrint,
  termReducer: lambdaReduce,
  termNormalizer: lambdaNormalize,
  samples: {
    code: lambdaSampleCode,
    func: lambdaSampleFunc,
  },
};

type SKLanguage = typeof SK;
type LambdaLanguage = typeof Lambda;

export const languages: Record<LanguageName, SKLanguage | LambdaLanguage> = {
  SK: SK,
  Lambda: Lambda,
};
