import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as reduxDispatch,
  useSelector as reduxSelector,
} from "react-redux";
import { formReducer } from "./formSlice";
import { lambdaReducer, skReducer } from "./machineSlices";
import { modalReducer } from "./modalSlice";
import { languageReducer } from "./languageSlice";

export const store = configureStore({
  reducer: {
    ui: combineReducers({ modal: modalReducer, form: formReducer }),
    language: languageReducer,
    machine: combineReducers({ SK: skReducer, Lambda: lambdaReducer }),
  },
});

type RootState = ReturnType<typeof store.getState>;
type Dispatch = typeof store.dispatch;

export const useDispatch: () => Dispatch = reduxDispatch;
export const useSelector: TypedUseSelectorHook<RootState> = reduxSelector;
