import { createSlice } from "@reduxjs/toolkit";
import { switchLanguage } from "./languageSlice";

type FormState = {
  code: string;
  function: string;
};

export const formSlice = createSlice({
  name: "library",
  initialState: {
    code: "",
    function: "",
  } as FormState,
  reducers: {
    updateCode: (state, action: { payload: string }) => {
      state.code = action.payload;
    },
    updateFunction: (state, action: { payload: string }) => {
      state.function = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(switchLanguage, (state) => {
      state.code = "";
      state.function = "";
    });
  },
});

export const { updateCode, updateFunction } = formSlice.actions;
export const formReducer = formSlice.reducer;
