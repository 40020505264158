import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Modal } from "../../generic/Modal/Modal";
import { closeModal } from "../../state/modalSlice";
import { useDispatch, useSelector } from "../../state/store";
import { OverviewContent } from "./OverviewContent/OverviewContent";
import { SKContent } from "./SKContent/SKContent";
import "react-tabs/style/react-tabs.css";
import { Button } from "../../generic/Button/Button";
import { LambdaContent } from "./LambdaContent/LambdaContent";

export const InstructionsModal = () => {
  const modal = useSelector((state) => state.ui.modal);
  const dispatch = useDispatch();

  return (
    <Modal
      isOpen={modal === "Instructions"}
      onRequestClose={() => dispatch(closeModal())}
    >
      <Tabs>
        <TabList>
          <Tab>Overview</Tab>
          <Tab>SK</Tab>
          <Tab>&lambda;</Tab>
        </TabList>
        <TabPanel>
          <OverviewContent />
        </TabPanel>
        <TabPanel>
          <SKContent />
        </TabPanel>
        <TabPanel>
          <LambdaContent />
        </TabPanel>
      </Tabs>
      <Button onClick={() => dispatch(closeModal())} type="Subdued">
        Close Instructions
      </Button>
    </Modal>
  );
};
