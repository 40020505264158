import { CSSProperties, MouseEventHandler, ReactNode } from "react";
import "./button.css";

type ButtonType = "Normal" | "Subdued";

type ButtonSize = "Normal" | "Small";

type ButtonProps = {
  disabled?: boolean;
  type?: ButtonType;
  size?: ButtonSize;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const generateClassName = ({ disabled, type, size }: ButtonProps) => {
  let str = "button";

  if (type === "Subdued") {
    str += " button-subdued";
  }
  if (size === "Small") {
    str += " button-small";
  }
  if (disabled) {
    str += " button-disabled";
  }

  return str;
};

export const Button = ({
  children,
  onClick = () => {
    /* Intentionally Empty */
  },
  disabled,
  style,
  type = "Normal",
  size = "Normal",
}: ButtonProps & { children: ReactNode }) => (
  <div
    className={generateClassName({ disabled, type, size })}
    style={style}
    onClick={(e) => !disabled && onClick(e)}
  >
    {children}
  </div>
);
