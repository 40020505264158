import React from "react";
import { Button } from "../../generic/Button/Button";
import { updateFunction } from "../../state/formSlice";
import { useDispatch, useSelector } from "../../state/store";
import { languageActions } from "../../state/machineSlices";
import "./FunctionArea.css";

export const FunctionArea = () => {
  const func = useSelector((state) => state.ui.form.function);
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  return (
    <React.Fragment>
      <h2>Function Box</h2>
      <div className="function-layout">
        <input
          type={"text"}
          onChange={(e) => {
            dispatch(updateFunction(e.target.value));
          }}
          value={func}
        />
        <Button
          disabled={func.trim().length === 0}
          onClick={() => {
            dispatch(languageActions[language].setTerm(func));
          }}
          size="Small"
        >
          Load
        </Button>
      </div>
    </React.Fragment>
  );
};
