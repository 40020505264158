import { DefinitionList, ParseNode } from "./parser";

export const reduce = (
  defs: DefinitionList,
  term: ParseNode
): ParseNode | "Irreducible" => {
  if (
    term.nature === "App" &&
    term.children[0].nature === "App" &&
    term.children[0].children[0].nature === "App" &&
    term.children[0].children[0].children[0].nature === "S"
  ) {
    const x = term.children[0].children[0].children[1];
    const y = term.children[0].children[1];
    const z = term.children[1];

    return {
      nature: "App",
      name: "",
      children: [
        { nature: "App", name: "", children: [x, z] },
        { nature: "App", name: "", children: [y, z] },
      ],
    };
  }

  if (
    term.nature === "App" &&
    term.children[0].nature === "App" &&
    term.children[0].children[0].nature === "K"
  ) {
    return term.children[0].children[1];
  }

  if (term.nature === "App") {
    const reducedLeft = reduce(defs, term.children[0]);

    if (reducedLeft !== "Irreducible") {
      return {
        nature: "App",
        name: "",
        children: [reducedLeft, term.children[1]],
      };
    }

    const reducedRight = reduce(defs, term.children[1]);

    if (reducedRight !== "Irreducible") {
      return {
        nature: "App",
        name: "",
        children: [term.children[0], reducedRight],
      };
    }

    return "Irreducible";
  }

  if (term.nature === "Ident" && typeof defs[term.name] !== "undefined") {
    return defs[term.name];
  }

  return "Irreducible";
};

export const normalize = (
  defs: DefinitionList,
  term: ParseNode
): ParseNode | "Irreducible" => {
  let previousTerm = term;
  let currentTerm = reduce(defs, term);

  if (currentTerm === "Irreducible") {
    return "Irreducible";
  }

  while (currentTerm !== "Irreducible") {
    previousTerm = currentTerm;
    currentTerm = reduce(defs, previousTerm);
  }

  return previousTerm;
};
