import { Token, TokenName } from "./scanner";

export type DefinitionList = Record<string, ParseNode>;

export type ParseNode =
  | { nature: "S"; name: "S" }
  | { nature: "K"; name: "K" }
  | { nature: "Ident"; name: string }
  | AppNode;

export type AppNode = {
  nature: "App";
  name: "";
  children: [ParseNode, ParseNode];
};

type ParseError =
  | ["Unexpected", TokenName | "EOF"]
  | ["Redeclaration", string]
  | ["Undefined", string]
  | ["Unknown", string];

export const isParseError = <T>(type: T | ParseError): type is ParseError => {
  const parseStrings: Array<ParseError[0]> = [
    "Redeclaration",
    "Undefined",
    "Unexpected",
    "Unknown",
  ];

  return (
    Array.isArray(type) && type.length == 2 && parseStrings.includes(type[0])
  );
};

const parseDefinition = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { name: string; definition: ParseNode; index: number } | ParseError => {
  // Verify that we haven't reached the end of the input
  if (typeof tokens[index] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (typeof tokens[index + 1] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (typeof tokens[index + 2] === "undefined") {
    return ["Unexpected", "EOF"];
  }

  // Check that the next two tokens constitute a definition
  if (tokens[index].type !== "Ident") {
    return ["Unexpected", tokens[index].type];
  }
  if (tokens[index + 1].type !== "Defsym") {
    return ["Unexpected", tokens[index + 1].type];
  }

  // Verify that the definition name does not already exist
  if (typeof definitions[tokens[index].raw] !== "undefined") {
    return ["Redeclaration", tokens[index].raw];
  }

  const name = tokens[index].raw;
  const result = parseTerm(definitions, tokens, index + 2);

  return isParseError(result)
    ? result
    : { name, definition: result.node, index: result.index };
};

export const parseTerm = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { node: ParseNode; index: number } | ParseError => {
  if (typeof tokens[index] === "undefined") {
    return ["Unexpected", "EOF"];
  }
  if (tokens[index].type === "Defsym") {
    return ["Unexpected", "Defsym"];
  }
  if (tokens[index].type === "Rparen") {
    return ["Unexpected", "Rparen"];
  }

  switch (tokens[index].type) {
    case "S":
      return { node: { nature: "S", name: "S" }, index: index + 1 };
    case "K":
      return { node: { nature: "K", name: "K" }, index: index + 1 };
    case "Ident":
      return {
        node: { nature: "Ident", name: tokens[index].raw },
        index: index + 1,
      };
    case "Lparen":
      return parseTermList(definitions, tokens, index);
  }

  return ["Unknown", tokens[index].type];
};

const parseTermList = (
  definitions: DefinitionList,
  tokens: Token[],
  index: number
): { node: ParseNode; index: number } | ParseError => {
  const parseResult = parseTerm(definitions, tokens, index + 1);

  if (isParseError(parseResult)) {
    return parseResult;
  }

  let currentNode = parseResult.node;
  let currentIndex = parseResult.index;

  while (
    currentIndex < tokens.length &&
    tokens[currentIndex].type !== "Rparen"
  ) {
    const parseResult = parseTerm(definitions, tokens, currentIndex);

    if (isParseError(parseResult)) {
      return parseResult;
    }

    currentNode = {
      nature: "App",
      name: "",
      children: [currentNode, parseResult.node],
    };

    currentIndex = parseResult.index;
  }

  // We've reached the end of the input
  if (currentIndex >= tokens.length) {
    // But, we haven't reached the end of the list...
    if (tokens[tokens.length - 1].type !== "Rparen") {
      return ["Unexpected", "EOF"];
    }
  }

  return { node: currentNode, index: currentIndex + 1 };
};

export const parseDefinitionList = (
  tokens: Token[]
): DefinitionList | ParseError => {
  const definitions: DefinitionList = {};

  let currentIndex = 0;

  while (currentIndex < tokens.length) {
    const result = parseDefinition(definitions, tokens, currentIndex);

    if (isParseError(result)) {
      return result;
    }

    definitions[result.name] = result.definition;
    currentIndex = result.index;
  }

  return definitions;
};
