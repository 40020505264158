import React from "react";
import { Button } from "../../generic/Button/Button";
import { useDispatch, useSelector } from "../../state/store";
import { updateCode, updateFunction } from "../../state/formSlice";
import { languageActions } from "../../state/machineSlices";
import { Link } from "../../generic/Link/Link";
import * as languages from "../../_languages/languages";
import "./CodeArea.css";

export const CodeArea = () => {
  const code = useSelector((state) => state.ui.form.code);
  const language = useSelector((state) => state.language);
  const dispatch = useDispatch();

  const sampleCode = languages[language].samples.code;
  const sampleFunc = languages[language].samples.func;

  return (
    <React.Fragment>
      <div className="code-header">
        <h2>Code Box</h2>
        <Link
          onClick={() => {
            dispatch(updateCode(sampleCode));
            dispatch(languageActions[language].setDefinitions(sampleCode));
            dispatch(updateFunction(sampleFunc));
            dispatch(languageActions[language].setTerm(sampleFunc));
          }}
        >
          Load Sample
        </Link>
      </div>
      <textarea
        onChange={(e) => {
          dispatch(updateCode(e.target.value));
        }}
        value={code}
      />
      <Button
        disabled={code.trim().length === 0}
        onClick={() => {
          dispatch(languageActions[language].setDefinitions(code));
        }}
      >
        Validate Code
      </Button>
    </React.Fragment>
  );
};
