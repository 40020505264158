import { ParseNode } from "./parser";

export const print = (term: ParseNode): string => {
  switch (term.nature) {
    case "Ident":
      return term.name + " ";
    case "Bvar":
      return `⌈${term.index}⌉` + " ";

    case "App": {
      const ret_val = print(term.children[0]);

      return term.children[1].nature === "App"
        ? ret_val + `( ${print(term.children[1])})`
        : ret_val + print(term.children[1]);
    }

    case "LcAbs": {
      let lambdaChain = "λ ";
      let nextTerm = term.children[0];

      while (nextTerm.nature === "LcAbs") {
        lambdaChain = lambdaChain + "λ ";
        nextTerm = nextTerm.children[0];
      }

      return `( ${lambdaChain} ( ${print(nextTerm)} ) ) `;
    }
  }
};

export const sampleCode = `
S $= ( .\\ x y z => ( ( x z ) ( y z ) ) )
K $= ( .\\ x y => x )
`
  .trim()
  .concat("\n");

export const sampleFunc = "( S K K hi )";
