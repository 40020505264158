import React from "react";

import program from "./program.png";
import definition from "./definition.png";
import term from "./term.png";

const Lambda = () => <React.Fragment>&lambda;&#8209;Calculus</React.Fragment>;
const Alpha = () => (
  <React.Fragment>
    <i>&alpha;</i>&#8209;Conversion
  </React.Fragment>
);
const Beta = () => (
  <React.Fragment>
    <i>&beta;</i>&#8209;Reduction
  </React.Fragment>
);
const Delta = () => (
  <React.Fragment>
    <i>&delta;</i>&#8209;Substitution
  </React.Fragment>
);

export const LambdaContent = () => (
  <React.Fragment>
    <section>
      <h3>Overview</h3>
      <p>
        The <Lambda /> is a minimal untyped functional language that is
        well-known in functional programming circles. Every definition in this
        language is a function, like the SK Calculus. However, unlike the SK
        Calculus, there are no pre-existing functions in the <Lambda />. All
        functions that one might wish to use must be defined. Even so, the
        language remains Turing&#8209;Complete.
      </p>
    </section>
    <section>
      <h3>Syntax</h3>
      <p>
        The syntax remains minimal for the <Lambda />, though there are some
        changes. The following sections present the syntax used for this
        implementation. In addition to the textual explanation given here, there
        is also a visual syntax diagram given at the end of the instructions.
      </p>
      <section>
        <h4>Definitions</h4>
        <p>
          In this implementation, function definitions have the form{" "}
          <code>id $= &lt;term&gt;</code> where <code>id</code> is any sequence
          of non-whitespace characters or symbols that itself is not a reserved
          string. (The reserved strings are shown below.) This very relaxed
          definition means that a character sequence like{" "}
          <code>&lt;&gt;&lt;</code> is allowed as an identifier.
        </p>
        <div className="reserved-strings">
          <div className="display">
            <code>(</code>
            <code>)</code>
            <code>.\</code>
            <code>=&gt;</code>
            <code>$=</code>
          </div>
          <div className="caption">Reserved Strings</div>
        </div>
        <p></p>
      </section>
      <section>
        <h4>Terms</h4>
        <p>
          The form of a <code>&lt;term&gt;</code> is also relatively simple.
          There are only three possibilities:
        </p>
        <div className="display">
          <ol>
            <li>
              Some <code>id</code> written entirely by itself
            </li>
            <li>
              A function application, i.e,{" "}
              <code>
                ( &lt;term<sub>1</sub>&gt; ... &lt;term<sub>n</sub>&gt; )
              </code>
            </li>
            <li>
              A function expression, i.e.,{" "}
              <code>
                ( .\ &lt;id<sub>1</sub>&gt; ... &lt;id<sub>n</sub>&gt; =&gt;
                &lt;term&gt; )
              </code>
            </li>
          </ol>
        </div>
        <p>
          It should be noted that both function application and function
          expressions are abbreviations for single-argument functions. In
          particular, function application is left-associative, while function
          abstraction is right-associative. Ultimately, this means that every
          function is a single-argument function, even if it has been presented
          as a multi-argument function.
        </p>
      </section>
    </section>
    <section>
      <h3>Semantics</h3>
      <p>
        There are three logical operations in the <Lambda /> using the syntax
        presented in these instructions, namely, <Alpha />, <Beta />, and{" "}
        <Delta />
      </p>

      <h4>
        <Alpha />
      </h4>
      <p>
        In the <Lambda />, the &lambda; (or its code equivalent) is a binder for
        the identifiers between it and the following arrow. As such, there needs
        to be a way to rename such identifiers. That&apos;s the general purpose
        of <Alpha />
      </p>
      <p>
        As an example, take the definition{" "}
        <code>Pair $= ( .\ x y =&gt; ( x y ) ) </code> as the function in
        question. Then, per a <Beta />, as described below, <code>x</code> must
        be substituted with <code>y</code> via the reduction. Since{" "}
        <code>y</code> is already bound in <code>Pair</code>, the bound{" "}
        <code>y</code> must be renamed for the substitution to be valid. Thus,
        we can rewrite <code>Pair</code> as show below.
      </p>
      <div className="display">
        <code>Pair $= ( .\ u v =&gt; ( u v ) ) </code>
      </div>
      <p>
        This example showcases the reason for <Alpha />, but in an
        implementation, other means can be used to prevent substitution issues.
        In particular, the internals of this implementation use the
        &quot;locally nameless&quot; representation of terms, which replaces
        bound variables with indices to the nearest binder above. It is this
        representation that is shown in the Representation Area and when a
        term is show in the Term Display modal.
      </p>

      <h4>
        <Beta />
      </h4>
      <p>
        Since every definition is a function in the <Lambda />, there must be a
        way to evaluate a function, given its argument. This is the purpose of{" "}
        <Beta />
      </p>
      <p>
        First, recall that every function is a function of one argument, thus,
        each term can bind only a single identifier. Now, order to evaluate a
        function, logically, the bound identifier is replaced with the argument
        throughout an expression. For example, given the definition{" "}
        <code>M $= ( .\ x =&gt; ( x x ) ) </code>, and the expression{" "}
        <code>( M y )</code>, the result of application is shown below.
      </p>
      <div className="display">
        <code>( y y )</code>
      </div>
      <p>
        Such applications, where the left is an abstraction, are called{" "}
        <i>&beta;</i>-redexes.
      </p>

      <h4>
        <Delta />
      </h4>
      <p>
        Alongside the need to rename variables and evaluate functions, this
        implementation of the <Lambda /> also requires a way to eliminate
        definitions. This is the purpose of <Delta />
      </p>
      <p>
        Logically, in <Delta />, an identifier that is a name of a definition is
        replaced by the definitional term. For example, imagine the definition{" "}
        <code>id $= ( .\ x =&gt; x )</code> for the identity function, and the
        term <code>( id mac )</code> as an expression. The result of <Delta />{" "}
        in this case would be <code>( ( .\ x =&gt; x ) mac )</code>, eliminating
        the
        <code>id</code> in the original expression by replacing it with the
        definition.
      </p>
      <p>
        Such terms, those that are names of definitions, are called{" "}
        <i>&delta;</i>-redexes.
      </p>

      <h4>Evaluation</h4>
      <p>
        In order to fully evaluate a term, all redexes (both <i>&beta;</i> and{" "}
        <i>&delta;</i>) must be reduced. In this implementation, each function
        is evaluated by starting with the outer-most, left-most redex. That
        redex is reduced or substituted, as appropriate, and the steps continue
        from there.
      </p>
      <p>
        Note that free identifiers that have no definition remain exactly as
        they are. Once no redexes remain, the evaluation of the function is
        finished. Correctly interpreting the final reduced expression will
        provide the value of the calculation.
      </p>
      <p>
        Beyond that, nothing more is needed to use the <Lambda /> as a
        programming language. Enjoy!
      </p>
    </section>
    <section>
      <h3>Diagram</h3>
      <div className="railroad-diagram">
        <div>
          <div className="caption">Program</div>
          <img src={program} className="railorad" />
        </div>
        <div>
          <div className="caption">Definition</div>
          <img src={definition} />
        </div>
        <div>
          <div className="caption">Term</div>
          <img src={term} />
        </div>
      </div>
    </section>
  </React.Fragment>
);
