export type TokenName = "S" | "K" | "Ident" | "Lparen" | "Rparen" | "Defsym";

export type Token = {
  type: TokenName;
  raw: string;
};

const selectToken = (tok: string): Token => {
  switch (tok) {
    case "S":
      return { type: "S", raw: tok };
    case "K":
      return { type: "K", raw: tok };
    case "(":
      return { type: "Lparen", raw: tok };
    case ")":
      return { type: "Rparen", raw: tok };
    case "$=":
      return { type: "Defsym", raw: tok };
    default:
      return { type: "Ident", raw: tok };
  }
};

export const scan = (input: string): Token[] =>
  input
    .split(/\s+/)
    .filter((str) => str.trim().length !== 0)
    .map(selectToken);
