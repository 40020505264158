import { CodeArea } from "../CodeArea/CodeArea";
import { FunctionArea } from "../FunctionArea/FunctionArea";
import { InterpreterArea } from "../InterpreterArea/InterpreterArea";
import "./Main.css";

export const Main = () => (
  <main>
    <div className="column">
      <CodeArea />
    </div>
    <div className="column">
      <FunctionArea />
      <InterpreterArea />
    </div>
  </main>
);
