import React from "react";
import { Provider } from "react-redux";
import { Footer } from "./layout/Footer/Footer";
import { Header } from "./layout/Header/Header";
import { Main } from "./layout/Main/Main";
import { store } from "./state/store";
import { ToastContainer, Zoom } from "react-toastify";
import { TermModal } from "./modals/TermModal/TermModal";
import { InstructionsModal } from "./modals/InstructionsModal/InstructionsModal";
import { CalculusSelectModal } from "./modals/CalculusSelectionModal/CalculusSelectionModal";

import "react-toastify/dist/ReactToastify.css";

const Modals = () => (
  <React.Fragment>
    <InstructionsModal />
    <CalculusSelectModal />
    <TermModal />
  </React.Fragment>
);

export const App = () => {
  return (
    <Provider store={store}>
      <Header />
      <Main />
      <Footer />
      <Modals />
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        theme="light"
        transition={Zoom}
        draggable={false}
        pauseOnHover={false}
        pauseOnFocusLoss={true}
        closeOnClick={true}
        closeButton={true}
      />
    </Provider>
  );
};
