import "./Footer.css";
import logo from "./logo.png";

export const Footer = () => (
  <footer>
    <div className="logo-container">
      <img src={logo} className="logo" />
    </div>
  </footer>
);
